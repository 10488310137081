import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { AffordableLogo } from '@svg/graphic/affordable-logo';
import { CSDB } from '@svg/graphic/csdb';
import { GroningersLogo } from '@svg/graphic/groningers';

export const LandingLogoCycler = () => {
  const [firstSlide, setFirstSlide] = useState(2);
  const [secondSlide, setSecondSlide] = useState(1);
  const [thirdSlide, setThirdSlide] = useState(0);
  const largestLogosContainerRef = useRef<HTMLDivElement>(null);

  const transitions = {
    first: {
      zIndex: 3,
      scale: 1, // zoom forward effect
      opacity: 1,
      y: 0, // zoom forward effect
      transition: {
        duration: 1,
      },
    },
    second: {
      zIndex: 2,
      y: -25, // zoom forward effect
      scale: 0.85, // zoom forward effect
      opacity: 0,
      transition: {
        duration: 1,
      },
    },
    third: {
      zIndex: 1,
      y: -25, // zoom forward effect
      scale: 0.85, // zoom forward effect
      opacity: 0,
      transition: {
        duration: 1,
      },
    },
    exit: {
      zIndex: 4,
      opacity: 0,
      scale: 0.5, // zoom back effect
      transition: {
        duration: 0.3,
      },
    },
    enter: {
      zIndex: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  const setSlidePosition = (slide: number) => {
    switch (slide) {
      case 0:
        return 'third';
      case 1:
        return 'second';
      case 2:
        return 'first';
      case 3:
        return 'exit';
      default:
        return 'enter';
    }
  };

  useEffect(() => {
    const incrementSlidePosition = setInterval(() => {
      setFirstSlide(firstSlide + 1);
      setSecondSlide(secondSlide + 1);
      setThirdSlide(thirdSlide + 1);
    }, 4000);
    return () => clearInterval(incrementSlidePosition);
  }, [firstSlide, secondSlide, thirdSlide]);

  // Timing the move from exit to enter stages in the animation
  useEffect(() => {
    if (firstSlide === 3) {
      setTimeout(() => {
        setFirstSlide(4);
        setTimeout(() => {
          setFirstSlide(0);
        }, 250);
      }, 150);
    }
    if (secondSlide === 3) {
      setTimeout(() => {
        setSecondSlide(4);
        setTimeout(() => {
          setSecondSlide(0);
        }, 250);
      }, 150);
    }
    if (thirdSlide === 3) {
      setTimeout(() => {
        setThirdSlide(4);
        setTimeout(() => {
          setThirdSlide(0);
        }, 250);
      }, 150);
    }
  }, [firstSlide, secondSlide, thirdSlide]);

  const data = useStaticQuery(graphql`
    {
      d11: file(relativePath: { eq: "d11-logo.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      d20: file(relativePath: { eq: "d20-logo.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      d3: file(relativePath: { eq: "d3-logo.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cycling: file(relativePath: { eq: "usa-cycling-logo.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      triathlon: file(relativePath: { eq: "usa-triathlon-logo.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ascent: file(relativePath: { eq: "pp-ascent-logo.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Container logosContainerHeight={2000}>
      <SizeContainer>
        <Logos
          ref={largestLogosContainerRef}
          initial="first"
          variants={transitions}
          animate={setSlidePosition(firstSlide)}
        >
          <Img fixed={data.d11.childImageSharp.fixed} />
          <Img fixed={data.d3.childImageSharp.fixed} />
          <Img fixed={data.d20.childImageSharp.fixed} />
        </Logos>

        <Logos
          initial="second"
          variants={transitions}
          animate={setSlidePosition(secondSlide)}
        >
          <Img fixed={data.triathlon.childImageSharp.fixed} />
          <Img fixed={data.cycling.childImageSharp.fixed} />
          <Img fixed={data.ascent.childImageSharp.fixed} />
        </Logos>

        <Logos
          initial="third"
          variants={transitions}
          animate={setSlidePosition(thirdSlide)}
        >
          <AffordableLogo scale={0.015} />
          <GroningersLogo scale={0.015} />
          <CSDB scale={0.2} />
        </Logos>
      </SizeContainer>
    </Container>
  );
};

interface ContainerProps {
  logosContainerHeight: number;
}

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin: 0;
`;

const SizeContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Logos = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;
