import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { motion } from 'framer-motion';

import { colors, fonts } from '@utilities/theme';
import { Button } from '@components/Button';
import { Icon } from '@svg/icon';
import { GatsbyImage } from '@utilities/gatsby-image';

interface Props {
  icon: JSX.Element;
  buttonText: string;
  linkTo: string;
  picture: string;
  heading: string;
  className?: string;
}

export const Card: React.FC<Props> = ({
  icon,
  buttonText,
  linkTo,
  picture,
  heading,
  children,
  className,
}) => {
  const data = useStaticQuery(graphql`
    {
      left: file(relativePath: { eq: "store_front_cropped.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      right: file(relativePath: { eq: "value-cards/right-card-pic.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <CardContainer className={className}>
      <CardPicture>
        <Picture
          fluid={
            picture === 'left'
              ? data.left.childImageSharp.fluid
              : data.right.childImageSharp.fluid
          }
          alt="picture"
        />
        <PictureForeground>
          <h3>{heading}</h3>
        </PictureForeground>
      </CardPicture>
      <ContentGrid>
        <IconContainer>{React.cloneElement(icon)}</IconContainer>
        <Paragraph>{children}</Paragraph>
        <A to={linkTo}>
          <motion.div
            whileHover={{
              y: -2,
              boxShadow: '0px 13px 27px rgba(50, 50, 93, 0.25)',
              transition: {
                type: 'spring',
              },
            }}
          >
            <ModifiedButton red icon={<ArrowIcon scale={2} name="arrow" />}>
              {buttonText}
            </ModifiedButton>
          </motion.div>
        </A>
      </ContentGrid>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background: white;
  color: ${colors.black};
  width: 400px;
  border-radius: 2px;
  height: 525px;
  display: grid;
  grid-template-rows: 1fr 2fr;
  grid-auto-flow: row;
  box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.025),
    0px 13px 27px rgba(50, 50, 93, 0.25);
`;

const CardPicture = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  max-height: 200px;
  z-index: 1;
`;

const PictureForeground = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  z-index: 2;

  & h3 {
    font-family: ${fonts.primary};
    font-size: 42px;
    color: white;
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    justify-self: center;
    align-self: end;
    margin-bottom: 5px;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
  }
`;

const Picture = styled(Img)<GatsbyImage>`
  z-index: 0;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  width: 100%;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  padding: 25px;
`;

const IconContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  justify-self: center;
`;

const Paragraph = styled.p`
  color: ${colors.black};
  font-size: 21px;
  font-family: ${fonts.secondary};
  line-height: 1.75;
`;

const ModifiedButton = styled(Button)`
  font-size: 24px;
  padding: 0.5em 1em;
`;

const A = styled(Link)`
  cursor: pointer;
  justify-self: center;
  text-decoration: none;
`;

const ArrowIcon = styled(Icon)`
  margin-left: 1em;
  filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.15));
`;
