import React from 'react';

interface Props {
  className?: string;
  color?: string;
  scale?: number;
}

export const RedBlurb = ({
  className,
  color = '#EC1C24',
  scale = 1,
}: Props) => {
  return (
    <svg
      width={426 * scale}
      height={1119 * scale}
      viewBox="0 0 426 1119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M82.2555 540.326C-184.034 -191.783 254.708 20.6196 569.218 57.285L554.389 1267.71L524.993 1266.18C387.659 1064.88 137.624 692.551 82.2555 540.326Z"
        fill={color}
      />
    </svg>
  );
};
