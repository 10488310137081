import React from 'react';

interface Props {
  className?: string;
  scale?: number;
  color1?: string;
  color2?: string;
  color3?: string;
  color4?: string;
  color5?: string;
  color6?: string;
}

export const CSDB = ({
  scale = 1,
  color1 = '#ED3D40',
  color2 = '#EE3E41',
  color3 = '#E54144',
  color4 = '#EA3D42',
  color5 = '#D8464C',
  color6 = '#373435',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={528.91 * scale}
      height={336.04 * scale}
      viewBox="0 0 528.91 336.04"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_1-3" data-name="Layer_1">
            <g id="_2090653871616" data-name=" 2090653871616">
              <path
                fill={color1}
                d="M90.25,198.43c-6,2.63-14.16,6.45-20,9.19,1-7.33,2.44-15.6,2.78-21.79-3.42-3-11.63-11.61-14-14.1l21-3.23L89,150.67a205.93,205.93,0,0,1,10,18.45l20.57,3.41c-2,2.17-11.54,11.63-14.78,14.73l2.87,21c-2.15-1.23-14.65-8.08-17.42-9.87Zm121.58,36.41c8.15,1.86,18,.23,23.22-3.52s9.71-10.71,6.4-19.58c-2.17-5.82-10.48-9.6-15.91-11.71-13.88-5.41-29.18-7.75-41.54-14.82-12-6.85-18.58-17.1-20.53-35-8,2.54-22.76,7.67-29.58,10.22-1.52-2.18-2.23-4.1-4-6.75s-4.57-6.6-6.59-8c-2.56-1.83-3.32-3.7-9.84-7.31-15.62-8.64-31.91-7.62-45.65-.06-38.39,21.15-29.68,82.07,18.24,87.83,11.41,1.37,22-2.15,30.28-7.44,10.33-6.59,12.44-12.68,17.54-20.59,5.91,3.14,26.84,10.32,35.15,13.73,1.55,4.7,21.69,18.06,42.79,23.05Z"
              />
              <path
                fill={color2}
                d="M377.26,193.47c8.6-38.3-13.5-68.84-61.73-66.77.83,23,2.43,77,.34,103.66,4.58,0,28.63-.05,40-7.09,11-6.8,18.43-16.58,21.4-29.8Z"
              />
              <path
                fill={color3}
                d="M.65,280.51c4.58,1.74,432.76.71,494.46.69,9,0,25,.93,33.23-.32.64-9-.56-6.24-26.71-6.24-50.91,0-494.47-1-500.84.41l-.14,5.46Z"
              />
              <path
                fill={color3}
                d="M528.91,70.13c-5.24-1.48-116.9-.51-132.47-.51-20.78,0-388.71-1-396.06.41l.24,6.18,528.28-.39V70.13Z"
              />
              <path
                fill={color4}
                d="M1.69,139.93C3.29,130.46,27.47,96,49.25,91.62L1.81,91.49l-.12,48.44Z"
              />
              <path
                fill={color4}
                d="M166.5,122.61C170,110,180.58,94.48,189,91.26l-61.23.13c15.35,4.46,32.51,17.3,38.74,31.22Z"
              />
              <path
                fill={color4}
                d="M1.67,238.57l.23,22.58,38.13.07C23.46,255.36,3.87,227,1.68,218.63v19.94Z"
              />
              <path
                fill={color3}
                d="M410.19,121.8l-.34-30.54-40,.13C381.08,94,403.11,105.49,410.19,121.8Z"
              />
              <path
                fill={color3}
                d="M267.34,132.73c-9-4.31-13.18-7.74-24.13-10-5-1.05-11.53-1.25-16.35.16-7.66,2.23-18.08,12-10.57,21.88,6.07,8,25,12.06,37,16,3.78,1.22,9.72,4.43,13.43,4.62,0-4.91.87-28.55.58-32.58Z"
              />
              <path
                fill={color3}
                d="M458.6,195.51l-.21,38.69c18.08,1.36,51.94-.43,43.35-25.83A17.88,17.88,0,0,0,500.2,205c-5.92-10.43-30.92-10.45-41.6-9.45Z"
              />
              <path
                fill={color3}
                d="M381.22,261.12l29,.33c0-7.17,0-1.05-.06-16.35l-.05-8.86C409.1,242.28,389.72,259.33,381.22,261.12Z"
              />
              <path
                fill={color3}
                d="M479.71,157.33c13-5.74,19.89-38.69-21-34.31l-.29,36.1,13,0A17,17,0,0,0,479.71,157.33Z"
              />
              <path
                fill={color5}
                d="M138.06,261.2l24.15.07c-3.41-.84-11.35-5.95-12-9-1.34,3-7,6.85-12.15,8.92Z"
              />
              <path
                fill={color5}
                d="M511.63,91.28l9.09,5.92c6.34,5.25,5.73,5.43,7.69,9.3L528,91.3l-16.41,0Z"
              />
              <path
                fill={color5}
                d="M515.38,172.53c3.74.32,10,1.21,12.92,4l.08-15.94-.39.35-4.82,4.45-7.79,7.18Z"
              />
              <g>
                <path
                  fill={color6}
                  d="M35.35,29.16l-1.17.46c-3.11-6.44-7.39-10.15-12.95-10.15C13.12,19.47,9.5,27.3,9.5,38.15s3.57,18.62,11.73,18.62A12.56,12.56,0,0,0,33.52,47.2l2.29.87c-2.29,7.07-7.09,11.25-14.89,11.25-10,0-17-8.93-17-21.17s7-21.17,17-21.17a14,14,0,0,1,9,2.9l.76-2h1.48Z"
                />
                <path
                  fill={color6}
                  d="M73.48,38.15c0,12.24-6.47,21.17-17,21.17S39.58,50.39,39.58,38.15,46.06,17,56.51,17,73.48,25.91,73.48,38.15Zm-5.6,0c0-10.85-3-18.68-11.37-18.68S45.19,27.3,45.19,38.15s3,18.62,11.32,18.62S67.88,49,67.88,38.15Z"
                />
                <path
                  fill={color6}
                  d="M92.09,55.84c7.19,0,11.06-3.6,14.78-8.82l1,.76-5,10.67H77.25V57c4.08-.29,5-.64,5-5V24.34c0-4.46-.87-4.75-5-5V17.85H92.19V19.3c-4.08.29-4.94.58-4.94,5V54.1c0,1.1.56,1.74,1.53,1.74Z"
                />
                <path
                  fill={color6}
                  d="M143.68,38.15c0,12.24-6.47,21.17-17,21.17s-16.92-8.93-16.92-21.17S116.25,17,126.7,17,143.68,25.91,143.68,38.15Zm-5.61,0c0-10.85-3-18.68-11.37-18.68s-11.31,7.83-11.31,18.68,3,18.62,11.31,18.62S138.07,49,138.07,38.15Z"
                />
                <path
                  fill={color6}
                  d="M177.43,53.52c1.22,1.91,2.55,3.19,5.91,3.48v1.45h-8.72l-12.38-20h-4.8V52c0,4.41.87,4.76,4.95,5v1.45H147.45V57c4.08-.29,5-.64,5-5V24.34c0-4.46-.62-4.75-5-5V17.85h17.69c8.46,0,14.43,2.78,14.43,10.32,0,6.67-4.95,9.69-11.68,10.15ZM159,20.46c-1,0-1.53.64-1.53,1.74V35.94h7.65c5.71,0,8.87-2.43,8.87-7.77s-3.16-7.71-8.82-7.71Z"
                />
                <path
                  fill={color6}
                  d="M213,52.76c.87,2.73,1.89,4,4.28,4.24v1.45H204V57c3.82-.29,4.69-1.16,3.87-3.6L205.41,46H191.5l-2.45,7.42c-.77,2.21.41,3.31,4.33,3.6v1.45H182.06V57c2.55-.29,3.78-1.51,4.59-4l11.78-35.2h2.91Zm-20.65-9.33h12.19l-6.17-18.16Z"
                />
                <path
                  fill={color6}
                  d="M218.16,58.45V57c4.08-.29,4.94-.64,4.94-5V24.34c0-4.46-.86-4.75-4.94-5V17.85h16.06c11.67,0,19.11,6.67,19.11,20.3s-7.44,20.3-19.11,20.3Zm11.52-38c-1,0-1.53.64-1.53,1.74V54.1c0,1.1.56,1.74,1.53,1.74h4.54c7.59,0,13.51-3.31,13.51-17.69s-5.92-17.69-13.51-17.69Z"
                />
                <path
                  fill={color6}
                  d="M291.72,38.15c0,12.24-6.47,21.17-17,21.17s-16.92-8.93-16.92-21.17S264.29,17,274.74,17,291.72,25.91,291.72,38.15Zm-5.61,0c0-10.85-3-18.68-11.37-18.68s-11.31,7.83-11.31,18.68,3,18.62,11.31,18.62S286.11,49,286.11,38.15Z"
                />
                <path
                  fill={color6}
                  d="M341.13,46.67c0,7.43-5.81,12.65-13.87,12.65a21.1,21.1,0,0,1-11.36-3.25L315,58.45H313.5L310.19,46l1.17-.41c3.57,7.72,9.43,11.25,15.75,11.25,5.25,0,9.48-3.48,9.43-8.35-.05-5.51-5.05-7.07-11.72-9-6.27-1.85-12.65-4-12.65-11.77,0-6.21,5.41-10.67,12.19-10.67a17.16,17.16,0,0,1,10.14,2.9l.72-2h1.53l3.11,11.31-1.13.46c-2.8-6.49-7.29-10.15-14.07-10.15-5,0-8,3-8,6.44,0,4.7,4.84,6.21,10.55,7.89C333.84,35.71,341.13,38,341.13,46.67Z"
                />
                <path
                  fill={color6}
                  d="M377.07,29.16l-1.17.46c-3.11-6.44-7.4-10.15-12.95-10.15-8.11,0-11.73,7.83-11.73,18.68S354.79,56.77,363,56.77a12.54,12.54,0,0,0,12.28-9.57l2.3.87c-2.3,7.07-7.09,11.25-14.89,11.25-10,0-17-8.93-17-21.17s7-21.17,17-21.17a14,14,0,0,1,9,2.9l.76-2h1.48Z"
                />
                <path
                  fill={color6}
                  d="M402.81,58.45V57c4.08-.29,4.95-.64,4.95-5V38.55H390.58V52c0,4.41.86,4.76,4.94,5v1.45H380.59V57c4.07-.29,4.94-.64,4.94-5V24.34c0-4.46-.87-4.75-4.94-5V17.85h14.93V19.3c-4.08.29-4.94.58-4.94,5v11.6h17.18V24.34c0-4.46-.87-4.75-4.95-5V17.85h14.94V19.3c-4.08.29-4.94.58-4.94,5V52c0,4.41.86,4.76,4.94,5v1.45Z"
                />
                <path
                  fill={color6}
                  d="M455.42,38.15c0,12.24-6.47,21.17-17,21.17s-16.93-8.93-16.93-21.17S428,17,438.45,17,455.42,25.91,455.42,38.15Zm-5.6,0c0-10.85-3-18.68-11.37-18.68s-11.32,7.83-11.32,18.68,3,18.62,11.32,18.62S449.82,49,449.82,38.15Z"
                />
                <path
                  fill={color6}
                  d="M493.81,38.15c0,12.24-6.48,21.17-17,21.17s-16.92-8.93-16.92-21.17S466.38,17,476.83,17,493.81,25.91,493.81,38.15Zm-5.61,0c0-10.85-3-18.68-11.37-18.68s-11.31,7.83-11.31,18.68,3,18.62,11.31,18.62S488.2,49,488.2,38.15Z"
                />
                <path
                  fill={color6}
                  d="M512.42,55.84c7.18,0,11.06-3.6,14.78-8.82l1,.76-5,10.67H497.58V57c4.08-.29,4.95-.64,4.95-5V24.34c0-4.46-.87-4.75-4.95-5V17.85h14.94V19.3c-4.08.29-4.95.58-4.95,5V54.1c0,1.1.56,1.74,1.53,1.74Z"
                />
              </g>
              <g>
                <path
                  fill={color6}
                  d="M6.32,305.06h5.22c2.38,0,2.83-1.19,3-3.49h.83v8.6h-.83c-.2-2.3-.65-3.49-3-3.49H6.32V315c0,2.73.54,2.95,3.12,3.13v.9H0v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9H17.85L20,299.7l-.67.4c-2-3.06-4.32-4.65-8.45-4.65H7.28c-.61,0-1,.4-1,1.08Z"
                />
                <path
                  fill={color6}
                  d="M43.52,306.43c0,7.6-4.09,13.14-10.73,13.14s-10.7-5.54-10.7-13.14,4.09-13.14,10.7-13.14S43.52,298.84,43.52,306.43Zm-3.55,0c0-6.73-1.9-11.59-7.18-11.59s-7.16,4.86-7.16,11.59S27.53,318,32.79,318,40,313.16,40,306.43Z"
                />
                <path
                  fill={color6}
                  d="M66.31,316a4.26,4.26,0,0,0,3.74,2.16v.9H64.54l-7.83-12.38h-3V315c0,2.73.55,2.95,3.12,3.13v.9H47.36v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.39-2.95-3.13-3.13v-.9H58.55c5.34,0,9.12,1.73,9.12,6.41,0,4.14-3.13,6-7.38,6.3ZM54.65,295.45c-.62,0-1,.4-1,1.08v8.53h4.83c3.61,0,5.61-1.51,5.61-4.82s-2-4.79-5.57-4.79Z"
                />
                <path
                  fill={color6}
                  d="M94.53,319H85.08v-.9c2.58-.18,3.13-.4,3.13-3.13V296.53c0-.68-.35-1.08-1-1.08-3.73,0-5,1-7.09,4.65l-.71-.4L81.93,293h.83a.93.93,0,0,0,.94.79H95.88a1,1,0,0,0,1-.79h.8l2.48,6.66-.71.4c-2-3.68-3.57-4.65-7-4.65-.61,0-1,.4-1,1.08V315c0,2.73.55,2.95,3.13,3.13Z"
                />
                <path
                  fill={color6}
                  d="M115.7,319v-.9c2.58-.18,3.13-.4,3.13-3.13v-8.32H108V315c0,2.73.55,2.95,3.13,3.13v.9h-9.45v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9h9.45v.9c-2.58.18-3.13.36-3.13,3.13v7.2h10.86v-7.2c0-2.77-.55-2.95-3.13-3.13v-.9h9.45v.9c-2.58.18-3.13.36-3.13,3.13V315c0,2.73.55,2.95,3.13,3.13v.9Z"
                />
                <path
                  fill={color6}
                  d="M137.75,317.41c4.87,0,7.32-2.23,9.67-5.47l.68.47L144.94,319H127.12v-.9c2.58-.18,3.12-.4,3.12-3.13V297.86c0-2.77-.54-2.95-3.12-3.13v-.9H145l2.16,5.87-.68.4c-2-3.06-4.31-4.65-8.44-4.65H134.4c-.61,0-1,.4-1,1.08v8.53h5.23c2.38,0,2.83-1.19,3-3.49h.84v8.6h-.84c-.19-2.3-.64-3.49-3-3.49h-5.23v9.65c0,.68.36,1.08,1,1.08Z"
                />
                <path
                  fill={color6}
                  d="M161.46,319v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9h10.15c7.38,0,12.09,4.14,12.09,12.6S179,319,171.61,319Zm7.29-23.58c-.62,0-1,.4-1,1.08v19.8c0,.68.35,1.08,1,1.08h2.86c4.81,0,8.54-2.05,8.54-11s-3.73-11-8.54-11Z"
                />
                <path
                  fill={color6}
                  d="M197.59,317.41c4.87,0,7.32-2.23,9.67-5.47l.68.47L204.78,319H187v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9h17.85L207,299.7l-.67.4c-2-3.06-4.32-4.65-8.45-4.65h-3.61c-.61,0-1,.4-1,1.08v8.53h5.22c2.38,0,2.83-1.19,3-3.49h.83v8.6h-.83c-.2-2.3-.65-3.49-3-3.49h-5.22v9.65c0,.68.35,1.08,1,1.08Z"
                />
                <path
                  fill={color6}
                  d="M229,315.5c.55,1.69,1.2,2.45,2.71,2.63v.9h-8.38v-.9c2.42-.18,3-.72,2.45-2.23l-1.58-4.61h-8.8l-1.54,4.61c-.49,1.37.25,2.05,2.74,2.23v.9h-7.16v-.9a3.11,3.11,0,0,0,2.9-2.45l7.45-21.85h1.83ZM216,309.71h7.7l-3.9-11.27Z"
                />
                <path
                  fill={color6}
                  d="M238.05,305.06h5.22c2.38,0,2.83-1.19,3-3.49h.83v8.6h-.83c-.2-2.3-.65-3.49-3-3.49h-5.22V315c0,2.73.54,2.95,3.12,3.13v.9h-9.44v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9h17.85l2.16,5.87-.67.4c-2-3.06-4.32-4.65-8.45-4.65H239c-.61,0-1,.4-1,1.08Z"
                />
                <path
                  fill={color6}
                  d="M282.61,315.5c.55,1.69,1.19,2.45,2.71,2.63v.9h-8.38v-.9c2.41-.18,3-.72,2.45-2.23l-1.58-4.61H269l-1.55,4.61c-.48,1.37.26,2.05,2.74,2.23v.9h-7.15v-.9a3.12,3.12,0,0,0,2.9-2.45l7.44-21.85h1.84Zm-13.05-5.79h7.7l-3.9-11.27Z"
                />
                <path
                  fill={color6}
                  d="M285.33,319v-.9c2.58-.18,3.16-.4,3.16-3.13V297.86c0-2.77-.58-2.95-3.16-3.13v-.9h7l11,18.83v-14.8c0-2.77-.45-2.95-3.06-3.13v-.9h7.8v.9c-2.58.18-3.09.36-3.09,3.13V319h-1.74l-13.15-22.46V315c0,2.73.58,2.95,3.15,3.13v.9Z"
                />
                <path
                  fill={color6}
                  d="M310.11,319v-.9c2.57-.18,3.12-.4,3.12-3.13V297.86c0-2.77-.55-2.95-3.12-3.13v-.9h10.15c7.38,0,12.08,4.14,12.08,12.6s-4.7,12.6-12.08,12.6Zm7.28-23.58c-.61,0-1,.4-1,1.08v19.8c0,.68.36,1.08,1,1.08h2.87c4.8,0,8.54-2.05,8.54-11s-3.74-11-8.54-11Z"
                />
                <path
                  fill={color6}
                  d="M360.25,319h-9.44v-.9c2.58-.18,3.13-.4,3.13-3.13V296.53c0-.68-.36-1.08-1-1.08-3.74,0-5,1-7.09,4.65l-.71-.4,2.48-6.66h.84c.16.54.45.79.93.79h12.19a.94.94,0,0,0,1-.79h.81l2.48,6.66-.71.4c-2-3.68-3.58-4.65-7.06-4.65-.61,0-1,.4-1,1.08V315c0,2.73.54,2.95,3.12,3.13Z"
                />
                <path
                  fill={color6}
                  d="M381.43,319v-.9c2.58-.18,3.13-.4,3.13-3.13v-8.32H373.7V315c0,2.73.54,2.95,3.12,3.13v.9h-9.44v-.9c2.58-.18,3.12-.4,3.12-3.13V297.86c0-2.77-.54-2.95-3.12-3.13v-.9h9.44v.9c-2.58.18-3.12.36-3.12,3.13v7.2h10.86v-7.2c0-2.77-.55-2.95-3.13-3.13v-.9h9.44v.9c-2.58.18-3.12.36-3.12,3.13V315c0,2.73.54,2.95,3.12,3.13v.9Z"
                />
                <path
                  fill={color6}
                  d="M403.48,317.41c4.86,0,7.31-2.23,9.67-5.47l.67.47L410.66,319H392.84v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9H410.7l2.16,5.87-.68.4c-2-3.06-4.32-4.65-8.44-4.65h-3.61c-.62,0-1,.4-1,1.08v8.53h5.22c2.38,0,2.84-1.19,3-3.49h.84v8.6h-.84c-.19-2.3-.65-3.49-3-3.49h-5.22v9.65c0,.68.35,1.08,1,1.08Z"
                />
                <path
                  fill={color6}
                  d="M427,319v-.9c2.58-.18,3.12-.4,3.12-3.13V297.86c0-2.77-.54-2.95-3.12-3.13v-.9h10.57c5.32,0,9.09,1.55,9.09,6.23,0,2.95-1.81,4.75-5,5.43,3.61.8,5.64,3.28,5.64,6.38,0,4.68-3.77,7.16-9.18,7.16Zm7.28-23.58c-.61,0-1,.4-1,1.08v8.17h4.54c3.25,0,5.25-1.33,5.25-4.64s-1.8-4.61-5.44-4.61Zm-1,10.84v10c0,.68.35,1.08,1,1.08h3.84c3.86,0,5.64-2.23,5.64-5.54s-2-5.58-5.26-5.58Z"
                />
                <path
                  fill={color6}
                  d="M459.09,317.41c4.55,0,7-2.23,9.35-5.47l.64.47L465.92,319H449.71v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9h9.45v.9c-2.58.18-3.13.36-3.13,3.13v18.47c0,.68.35,1.08,1,1.08Z"
                />
                <path
                  fill={color6}
                  d="M470.34,319v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9h9.44v.9c-2.57.18-3.12.36-3.12,3.13V315c0,2.73.55,2.95,3.12,3.13v.9Z"
                />
                <path
                  fill={color6}
                  d="M481.75,319v-.9c2.58-.18,3.16-.4,3.16-3.13V297.86c0-2.77-.58-2.95-3.16-3.13v-.9h7l11,18.83v-14.8c0-2.77-.45-2.95-3.06-3.13v-.9h7.8v.9c-2.58.18-3.1.36-3.1,3.13V319H499.7l-13.14-22.46V315c0,2.73.58,2.95,3.15,3.13v.9Z"
                />
                <path
                  fill={color6}
                  d="M506.22,319v-.9c2.58-.18,3.13-.4,3.13-3.13V297.86c0-2.77-.55-2.95-3.13-3.13v-.9h10.15c7.38,0,12.09,4.14,12.09,12.6S523.75,319,516.37,319Zm7.28-23.58c-.61,0-1,.4-1,1.08v19.8c0,.68.35,1.08,1,1.08h2.87c4.8,0,8.54-2.05,8.54-11s-3.74-11-8.54-11Z"
                />
              </g>
            </g>
          </g>
          <line fill={color6} x1="1.69" y1="1" x2="528.91" y2="1" />
          <line fill={color6} x1="1.69" y1="335.04" x2="528.91" y2="335.04" />
        </g>
      </g>
    </svg>
  );
};
