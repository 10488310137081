import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { motion } from 'framer-motion';

import { Header } from '@layout/Header';
import { Menu } from '@layout/Nav/MobileNav/Menu';
import { Seo } from '@components/Seo';
import { EONLogoLanding } from '@svg/graphic/eon-logo-landing';
import { Heading } from '@components/Headings';
import { LandingLogoCycler } from '@components/LandingLogoCycler';
import { Footer } from '@layout/Footer';
import { colors } from '@utilities/theme';
import { RedBlurb } from '@svg/graphic/red-blurb';
// import { ExcitementSpikes } from '../components/svg/graphic/excitement-spikes';
import { Card } from '@components/shared/Card';
import { Icon } from '@svg/icon';
import { GatsbyImage } from '@utilities/gatsby-image';

function IndexPage() {
  const data = useStaticQuery(graphql`
    {
      tshirt: file(relativePath: { eq: "tshirt-girl.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personal: file(relativePath: { eq: "value-cards/personal-touch.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <motion.div
      style={{ background: 'white' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Seo title="Home" />
      <Container>
        <Menu />
        <Blurb />
        <div>
          <Header page="landing" />
        </div>
        <Grid>
          <ContainerContent>
            <HeroLogo />
            <ContainerClients>
              <LargeHeading behindText="some of our">clients</LargeHeading>
              <LandingLogoCycler />
            </ContainerClients>
          </ContainerContent>
          <ContainerGirl>
            {/* <Spikes /> */}
            <ImageGirl
              imgStyle={{ objectFit: 'contain', maxHeight: '100%' }}
              fluid={data.tshirt.childImageSharp.fluid}
              alt="girl looking down at a screen printed t-shirt"
            />
          </ContainerGirl>
        </Grid>
      </Container>
      <SectionPersonalTouch>
        <HeadingPersonalTouch behindText="local in house">
          everything has a personal touch
        </HeadingPersonalTouch>
        <Cards
          buttonText="check out our catalogs!"
          heading="shop local"
          icon={<Icon name="house" />}
          linkTo="/catalogs"
          picture="left"
        >
          We have been in our current location for over 30 years, and with 40
          years combined experience there is little we can't handle.
        </Cards>
        <PersonalImage
          imgStyle={{ objectFit: 'cover' }}
          fluid={data.personal.childImageSharp.fluid}
          alt="image"
        />
        <Cards
          buttonText="learn more about us!"
          heading="personal touch"
          icon={<Icon name="personal-touch" scale={1.5} />}
          linkTo="/about"
          picture="right"
        >
          Our small size allows us to give special attention to each order so
          your products look exactly as you envisioned.
        </Cards>
      </SectionPersonalTouch>
      {/* <CarouselGallery /> */}
      <Footer />
    </motion.div>
  );
}

export default IndexPage;

const HeroLogo = styled(EONLogoLanding)`
  width: 80%;
  margin-top: -8vw;
  position: relative;
  z-index: 3;

  @media (min-width: 1620px) {
    margin-top: -6vw;
  }

  @media (min-width: 1765px) {
    margin-top: -4vw;
  }

  @media (min-width: 1970px) {
    margin-top: -2vw;
  }

  @media (min-width: 2220px) {
    margin-top: 0;
  }

  @media (max-width: 1083px) {
    width: 500px;
    margin-top: -140px;
  }

  @media (max-width: 675px) {
    width: 340px;
    margin-top: -200px;
    margin-bottom: -100px;
  }
`;

const Container = styled.div`
  background: white;
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 150px 1fr;
`;

const Grid = styled.div`
  max-width: 1440;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  /* z-index: 10; */
  padding: 0 10vw;
  /* width: 100%; */
  height: 100%;

  @media (max-width: 1083px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    justify-items: center;
  }
`;

const ContainerContent = styled.div`
  display: grid;
  grid-auto-flow: row;

  @media (max-width: 1083px) {
    justify-items: center;
  }
`;

const ContainerClients = styled.div`
  align-self: start;
  justify-self: start;
  margin-left: 0;
  margin-top: -15vw;
  width: 100%;

  @media (min-width: 1700px) {
    margin-top: -10vw;
  }

  @media (max-width: 1083px) {
    justify-self: initial;
  }
`;

const ContainerGirl = styled.div`
  align-self: end;
  margin-bottom: 0;
  grid-row: 1 / -1;
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(auto, calc(100vh - 150px));
  max-width: 500px;
  min-width: 500px;

  @media (min-width: 2000px) {
    max-width: 700px;
  }

  @media (max-width: 1340px) {
    min-width: 300px;
  }

  @media (max-width: 1083px) {
    display: none;
  }
`;

const ImageGirl = styled(Img)<GatsbyImage>`
  position: relative;
  /* z-index: 100; */
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  align-self: end;
  overflow: hidden;

  @media (min-width: 1190px) {
    margin-left: 0;
  }

  @media (max-width: 1083px) {
    display: none;
  }

  /* picture > img {
    max-height: 100%;
  }

  > img {
    max-height: 100%;
  } */
`;

// const Spikes = styled(ExcitementSpikes)`
//   grid-column: 1 / 2;
//   grid-row: 1 / 2;
//   /* justify-self: center; */
//   /* margin-top: 4vw;
//   margin-left: 14vw; */
// `;

const Blurb = styled(RedBlurb)`
  position: absolute;
  top: 0;
  right: 0;
  /* z-index: 0; */
  width: 35vw;
  min-height: 100%;

  @media (max-width: 1083px) {
    display: none;
  }
`;

const LargeHeading = styled(Heading)`
  padding-top: 50px;

  .behind {
    @media (max-width: 675px) {
      font-size: 60px;
    }

  .front {
    @media (max-width: 675px) {
      font-size: 48px;
    }
  }
`;

const SectionPersonalTouch = styled.section`
  background: ${colors.secondary};
  padding: 50px;
  display: grid;
  grid-template-rows: auto minmax(300px, 650px);
  grid-template-columns: auto minmax(200px, 600px) auto;
  grid-template-areas:
    'title title title'
    'left-card pic right-card';
  justify-content: center;
  column-gap: 5vw;
  margin-bottom: 200px;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto minmax(500px, 650px) auto;
    grid-template-areas:
      'title title'
      'pic pic'
      'left-card right-card';
    justify-content: space-around;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'left-card'
      'pic'
      'right-card';
  }
`;

const HeadingPersonalTouch = styled(LargeHeading)`
  margin-top: 50px;
  grid-area: title;

  .behind {
    margin-top: -42px;
    color: rgba(255, 255, 255, 0.25);
  }

  .front {
    color: white;
  }
`;

const PersonalImage = styled(Img)<GatsbyImage>`
  width: 100%;
  border-radius: 2px;
  grid-area: pic;

  picture > img {
    width: 100%;
    object-fit: cover;
  }

  > img {
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 1500px) {
    justify-self: center;
    max-width: 600px;
  }
`;

const Cards = styled(Card)`
  align-self: center;

  @media (max-width: 1500px) {
    align-self: initial;
    justify-self: center;
    margin-top: 50px;
  }
`;
